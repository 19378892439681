import Vue from 'vue'
import App from './App.vue'
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueMask from 'v-mask';
import router from './router'
import vuetify from './plugins/vuetify'
import './gtm.js'

Vue.config.productionTip = false

Vue.use(Toast, {
  // Setting the global default position
  position: POSITION.TOP_RIGHT,
});
Vue.use(VueMask);
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
