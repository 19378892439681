import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/BaseLayout'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home'),
      },
      {
        path: '/obrigado',
        name: 'Obrigado',
        component: () => import('../views/End'),
      },
      {
        path: '/selecionaMedico',
        name: 'SelecionaMedico',
        component: () => import('../views/SelecionaMedico'),
      },
      {
        path: '/selecionaExame',
        name: 'SelecionaExame',
        component: () => import('../views/SelecionaExame'),
      },
      {
        path: '/selecionaData',
        name: 'SelecionaData',
        component: () => import('../views/SelecionaData'),
      },
      {
        path: '/agendamento',
        name: 'Agendamento',
        component: () => import('../views/Agendamento'),
      },
    ],
  }
]

const router = new VueRouter({
  routes
})



export default router
